//Test URL
export const base_url = "https://ajit-backend-los-dev-eastus.azurewebsites.net";
// export const base_url2 = "https://dla-backend-dev-south-india.azurewebsites.net";
export const base_url2 = "https://dlav1.southindia.cloudapp.azure.com";
// export const base_url2 = "http://localhost:5000";
//export const base_url2 = "https://dla-backend-dev-south-india.azurewebsites.net";
//process.env.REACT_APP_BACKEND_URL;
//"https://dla-backend-uat-south-india.azurewebsites.net";
// "https://dla-backend-dev-south-india.azurewebsites.net";

//UAT
// https://ajit-backend-dla-uat-eastus.azurewebsites.net/
//DEV
//https://ajit-backend-dla-dev-eastus.azurewebsites.net

//production
//https://goshree-dla-prod-backend-ap-south-india.azurewebsites.net/

export const genInsight_base_url =   "https://genai-dla-backend-prod.southindia.cloudapp.azure.com/";

//export const genInsight_base_url = "http://genai-backend-dev.southindia.cloudapp.azure.com:8085/";